:root {
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
    overscroll-behavior: none;
}

html,
body,
#root,
#root>.app-container {
    height: 100%;
    max-height: 100vh;
    max-height: -webkit-fill-available;
}

::-webkit-scrollbar {
    width: 0.4rem;
}

::-webkit-scrollbar-thumb {
    border-radius: 12px;
    visibility: 'hidden';
}

/* Override the class given from katex/dist/katex.min.css to prevent extra whitespace appearing at the bottom of the page */
.katex .katex-mathml {
    position: absolute;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 0px;
    width: 0px;
    overflow: hidden;
}